import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import getApiUrl from "modules/api/getApiUrl";
import { RootState } from "store";
import { currentUserTokenSelector } from "store/features/currentUser/selectors";
import { PaginatedResult } from "store/utils/pagination";
import {
  AgreementConfiguration,
  AgreementWithTransactions,
  InvalidAgreement,
} from "types/entities/agreements/agreement-with-transaction";

export type FindAgreementsWithTransactionsParams = {
  offset?: number;
  limit?: number;
  user_id?: number[];
  agreement_type?: string[];
};

export const agreementsWithTransactionsApi = createApi({
  reducerPath: "agreementsWithTransactionsApi",

  baseQuery: fetchBaseQuery({
    baseUrl: getApiUrl(""),
    prepareHeaders: (headers, { getState }) => {
      const state = getState() as RootState;
      const token = currentUserTokenSelector(state);
      if (token) headers.set("x-auth", `${token}`);
      return headers;
    },
  }),

  endpoints: (builder) => ({
    getLastAgreementsWithTransactions: builder.query<
      PaginatedResult<
        AgreementWithTransactions<AgreementConfiguration> | InvalidAgreement
      >,
      FindAgreementsWithTransactionsParams | undefined
    >({
      query: (params) => ({
        url: "/agreements-with-transactions",
        params: params ?? {},
      }),
    }),
  }),
});
